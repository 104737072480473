import React from 'react'
import { asClassName } from '../../utils'
import styles from './button.module.scss'

interface ButtonProps {
  onClick: () => void
  black?: boolean
  white?: boolean

  warning?: boolean
  small?: boolean
  label: string
  disabled?: boolean
}
export const Button: React.FC<ButtonProps> = (props) => {
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={asClassName(
        styles.button,
        props.black ? styles.black : null,
        props.white ? styles.white : null,
        props.warning ? styles.warning : null,
        props.small ? styles.small : null,
      )}
    >
      {props.label}
    </button>
  )
}
