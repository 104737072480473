import { AppState, EDITABLE, VERSION } from '../../constants'
import { ActionType, TypedAction, User, versionType } from '../../interfaces'

/** The app version should only be set on init */
export const versionReducer = (state: versionType = VERSION, _action: TypedAction): versionType => {
  return state
}

/** Application state  */
export const readyStateReducer = (state: AppState = AppState.pristine, action: TypedAction): AppState => {
  switch (action.type) {
    /* Load creation */
    case ActionType.setReadyState:
      return action.payload
    case ActionType.loadCreation:
      return EDITABLE ? AppState.editingCreation : AppState.previewCreation
    /* Default */
    default:
      return state
  }
}

export const userReducer = (state: User = null, action: TypedAction): User => {
  switch (action.type) {
    /* Load creation */
    case ActionType.setUser:
      return action.payload
    /* Default */
    default:
      return state
  }
}
