import React from 'react'
import { useTypedSelector } from '../../store'
import { asClassName } from '../../utils'
import styles from './control-tab.module.scss'

interface TabButtonProps {
  isActive: boolean
  onClick: () => void
  label: string
}

interface TabContainerProps {
  isActive: boolean
}

export const TabNavigation: React.FC = (props) => {
  const isDesktop = useTypedSelector((state) => !state.local.isMobile)
  if (isDesktop) return <React.Fragment />
  return <div className={styles.buttons}>{props.children}</div>
}

export const TabButton: React.FC<TabButtonProps> = (props) => {
  return (
    <div
      className={asClassName(styles.button, props.isActive ? styles.active : null)}
      onClick={props.onClick}
    >
      {props.label}
    </div>
  )
}

export const TabContainer: React.FC<TabContainerProps> = (props) => {
  const isDesktop = useTypedSelector((state) => !state.local.isMobile)
  if (isDesktop) return <React.Fragment>{props.children}</React.Fragment>
  if (!props.isActive) return <React.Fragment />
  return <div className={styles.container}>{props.children}</div>
}
