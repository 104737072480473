import React from 'react'
import { IoSize } from '../../constants'
import { Variant } from '../../interfaces'
import { asClassName } from '../../utils'
import styles from './radio-button.module.scss'

interface RadioProps {
  isSelected: boolean
  onClick: () => void
  label: string
  disable?: boolean
}
export const RadioButton: React.FC<RadioProps> = (props) => {
  return (
    <div
      className={asClassName(styles.RadioButton, props.disable ? styles.soldOut : null)}
      onClick={props.onClick}
    >
      <div className={styles.radio}>
        <div className={asClassName(styles.check, props.isSelected ? styles.selected : null)} />
      </div>
      <div className={styles.label}>{props.label}</div>
    </div>
  )
}

interface VariantProps {
  isSelected: boolean
  variant: Variant
  total?: number
  fixWidth?: number
  onClick: () => void
}

export const VariantButton: React.FC<VariantProps> = (props) => {
  const total = props.total || 1
  const variant = props.variant

  const shipping = variant.onSale ? <h4>{variant.compareAtPrice}</h4> : null
  const variantWidth = props.fixWidth ? { minWidth: props.fixWidth } : { width: `${100 / total}%` }

  if (!variant.available) return null

  return (
    <div
      className={asClassName(styles.variantButton, props.isSelected ? styles.selected : null)}
      style={variantWidth}
      onClick={props.onClick}
    >
      <h2>
        {sizeToName(variant)}
        {'\n'}
        {variant.material}
      </h2>
      {shipping}
      <h3>{variant.price}</h3>
    </div>
  )
}

function sizeToName(variant: Variant): string {
  switch (variant.size) {
    /* Load creation */
    case IoSize.fiveInch:
      return '5"'
    case IoSize.sevenInch:
      return '7"'
    case IoSize.tenInch:
      return '10"'
    default:
      return 'x"'
  }
}
