import React, { useRef } from 'react'
import { PreviewImage } from '../../components/preview-image'
import { PreviewOverlay } from '../../components/preview-overlay'
import { SizeWarning } from '../../components/size-warning'
import { Carousel } from '../../components/video-carousel'
import { AppState } from '../../constants'
import { PreviewProvider } from '../../contexts/preview'
import dataCarousel from '../../data/carousel.json'
import { useElemSize } from '../../hooks/use-elem-size'
import { IVideo } from '../../interfaces'
import { useTypedSelector } from '../../store'
import { VideoContainer } from '../video-container'
import styles from './preview-container.module.scss'

export const VideoPreviewContainer: React.FC = () => {
  const containerEl = useRef<HTMLDivElement>(null)
  const containerSize = useElemSize(containerEl)

  return (
    <React.Fragment>
      <div ref={containerEl} className={styles.previewContainer}>
        <PreviewProvider>
          <PreviewImage containerSize={containerSize} layer='background' />
          <PreviewImage containerSize={containerSize} layer='device' />
          <VideoContainer containerSize={containerSize} />
          <PreviewImage containerSize={containerSize} layer='matte' />
          <SizeWarning />
        </PreviewProvider>
      </div>
      <PreviewOverlay />
    </React.Fragment>
  )
}

export const PreviewContainer: React.FC = () => {
  const readyState = useTypedSelector((state) => state.readyState)
  const variants = useTypedSelector(({ shopify }) => shopify.variants)

  switch (readyState) {
    case AppState.pristine:
      return (
        <div className={styles.containerCarrousel}>
          <Carousel storeVariants={variants} variantVideos={dataCarousel as IVideo[]} />
        </div>
      )
    default:
      return (
        <div className={styles.container}>
          <VideoPreviewContainer />
        </div>
      )
  }
}
