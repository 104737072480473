import React, { useRef } from 'react'
import { Button } from '../button'
interface FileInputProps {
  onFile: (file: File | null) => void
}

const FileInput: React.FC<FileInputProps> = (props) => {
  const fileInput = useRef<HTMLInputElement>(null)

  const onSelectChange = (_event: React.ChangeEvent<HTMLInputElement>) => {
    const files = fileInput.current?.files
    if (!files) return
    props.onFile(files[0])
  }

  return (
    <React.Fragment>
      <Button onClick={() => fileInput.current?.click()} label='Select Your video' />
      <input
        ref={fileInput}
        type='file'
        style={{ display: 'none' }}
        onChange={onSelectChange}
        accept='video/quicktime,video/mp4'
      />
    </React.Fragment>
  )
}

export default FileInput
