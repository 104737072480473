import firebase from 'firebase/compat/app'
import React, { useEffect, useState } from 'react'
import { CreationState, IS_DEV } from '../../constants'
import { actions, useDispatch, useTypedSelector } from '../../store'
import { useDebug } from '../../utils'
import styles from './upload.module.scss'

type FileRef = firebase.storage.Reference

interface UploadProps {
  onUploadComplete: () => void
}

export const UploadContainer: React.FC<UploadProps> = (props) => {
  const debug = useDebug('upload')
  const dispatch = useDispatch()
  const [uploadProgress, setProgress] = useState(0)
  const [fileRef, setFileRef] = useState<FileRef | null>(null)
  const { onUploadComplete } = props
  const uuid = useTypedSelector((state) => state.creation.uuid)
  const videoFile = useTypedSelector((state) => state.local.videoFile)
  const hasUploaded = useTypedSelector((state) => state.creation.readyState >= CreationState.hasUploaded)

  /** Create the file Reference in an specific Effect */
  useEffect(() => {
    if (!videoFile) return
    const name = `${uuid}.${videoFile.name.split('.').pop()}`
    const refPath = IS_DEV ? `video-dev/${name}` : `video/${name}`
    setFileRef(firebase.storage().ref().child(refPath))
    return () => setFileRef(null)
  }, [videoFile, uuid])

  /** Upload the file to the storage */
  useEffect(() => {
    if (hasUploaded) return
    if (!videoFile) return
    if (!fileRef) return

    debug('Started uploading to %s', fileRef.fullPath)

    /** Create the upload task */
    const uploadTask = fileRef.put(videoFile)

    /** Listen to the upload progress */
    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snap) => {
      setProgress((snap.bytesTransferred / snap.totalBytes) * 100)
    })

    /** Listen to the complete and errors  */
    uploadTask
      .then(() => debug('Completed'))
      .then(() => dispatch(actions.setOriginalResource(fileRef.fullPath)))
      .then(onUploadComplete)
      .catch((err) => console.error(err))

    /** Return a cleaning function */
    return () => {
      const result = uploadTask.cancel()
      if (result) debug('Cancelled upload')
    }
  }, [debug, dispatch, fileRef, hasUploaded, onUploadComplete, videoFile])

  return (
    <div className={styles.container}>
      <h2>
        Uploading
        <br />
        your creation
      </h2>
      <pre>{`${uploadProgress.toFixed(2)}%`}</pre>
      <div className='loader'>
        <div className='progress' style={{ width: `${uploadProgress}%` }} />
      </div>
    </div>
  )
}
