import 'rc-slider/assets/index.css'
import React from 'react'
import { useTypedSelector } from '../../store'
import { asClassName } from '../../utils'
import styles from './edit-controls.module.scss'
import './range-override.scss'

interface EditControlProps {
  label: string
  isActive: boolean
}

interface EditTabButtonProps {
  isActive: boolean
  onClick: () => void
  label: string
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>
  warn?: boolean
}

export const HackContainer: React.FC = (props) => {
  const isDesktop = useTypedSelector((state) => !state.local.isMobile)
  if (isDesktop) return <React.Fragment>{props.children}</React.Fragment>
  return <div className={styles.hack}>{props.children}</div>
}
export const EditTabsContainer: React.FC = (props) => {
  const isDesktop = useTypedSelector((state) => !state.local.isMobile)
  if (isDesktop) return <React.Fragment />
  return <div className={styles.buttons}>{props.children}</div>
}

export const EditTabButton: React.FC<EditTabButtonProps> = (props) => {
  const Icon = props.icon
  return (
    <div
      className={asClassName(
        styles.button,
        props.isActive ? styles.active : null,
        props.warn ? styles.warn : null,
      )}
      onClick={props.onClick}
    >
      <Icon className={styles.icon} />
      <h3>{props.label}</h3>
    </div>
  )
}

export const EditControl: React.FC<EditControlProps> = (props) => {
  const isDesktop = useTypedSelector((state) => !state.local.isMobile)
  if (!props.isActive && !isDesktop) return null
  return (
    <div className={styles.range}>
      {isDesktop ? <h3>{props.label}</h3> : null}
      {props.children}
    </div>
  )
}
