import React from 'react'
import { CreationState } from '../../constants'
import { useTypedSelector } from '../../store'
import styles from './preview-overlay.module.scss'
export const PreviewOverlay: React.FC = () => {
  const showOverlay = useTypedSelector((state) => state.creation.readyState >= CreationState.localVideo)
  if (showOverlay) return <React.Fragment />

  return <div className={styles.overlay} />
}
