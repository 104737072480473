import type React from 'react'
import { useTypedSelector } from '../../store'
import styles from './ships-in.module.scss'

export const ShipsIn: React.FC = () => {
  const shipsIn = useTypedSelector((state) => state.shopify.shipsIn)
  // if (!shipsIn) return null
  return (
    <div className={styles.container}>
      <div className={styles.icon}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          fill='currentColor'
          preserveAspectRatio='xMidYMid meet'
          viewBox='0 0 24 24'
        >
          <path d='M11.9509 22a.4495.4495 0 0 0 .0922-.032c.0301-.0169.0621-.032.0922-.0508l7.1936-4.0982a2.9786 2.9786 0 0 0 .9785-.8411c.2239-.3143.3349-.8073.3349-1.4734V8.3542a2.761 2.761 0 0 0-.0734-.698l-8.618 4.9242V22Zm-1.2588 0v-9.4271l-8.618-4.9243a2.763 2.763 0 0 0-.0734.698v7.1504c0 .6661.1129 1.1591.3406 1.4733.2608.3463.5925.633.9728.8411l7.203 4.0983.0884.0508a.3785.3785 0 0 0 .0866.0395Zm.6341-10.5373 3.9233-2.2241-8.7008-4.9695-3.372 1.9193a2.7376 2.7376 0 0 0-.5137.3594l8.6632 4.9149Zm5.2009-2.9485 3.4547-1.9664a2.9259 2.9259 0 0 0-.5061-.3594l-6.4861-3.6937a3.0598 3.0598 0 0 0-3.3362 0L7.7887 3.5522l8.7384 4.962Z' />
        </svg>
      </div>
      <span>Order now, ships in {shipsIn}</span>
    </div>
  )
}
