import React, { useEffect } from 'react'
import { IoMsgType, IS_EMBEDDED } from './constants'
import { ControllersContainer } from './containers/controllers-container'
import { PreviewContainer } from './containers/preview-container'
import { useCreationId } from './hooks/use-creation-id'
import { useFirebaseAuth } from './hooks/use-firebase-auth'
import { useMessages } from './hooks/use-messages'
import { useMobileDetection } from './hooks/use-mobile-detection'
import { useFirebase } from './services/firebase'
import { useTypedSelector } from './store'
import { useTimeout } from './utils'

export const Application: React.FC = () => {
  useMobileDetection()

  const firebaseInit = useFirebase()
  const [sendMessage, isConnectedToHost] = useMessages()
  const hasUser = useFirebaseAuth(firebaseInit)
  const [hasFetchID, foundCreation, hasCreationID] = useCreationId(firebaseInit)
  const time = hasCreationID ? 2000 : 1000
  const preload = useTimeout(time)
  const loadingTimeout = useTimeout(10000) // 10 seconds
  const version = useTypedSelector((state) => state.version)
  const hasVariants = useTypedSelector((s) => s.shopify.variants.length > 0)

  /** true when firebase and the user are ready */
  const firebase = [firebaseInit, hasUser].every((v) => v === true)

  /** true if the host has connected */
  const host = IS_EMBEDDED ? isConnectedToHost : true

  /** true has a creation if or does not need ibe */
  const creationId = hasCreationID ? [hasFetchID, foundCreation].every((v) => v === true) : true

  /** true if it has product data, or does not need one */
  const productData = hasCreationID ? true : hasVariants

  const conditions: Record<string, boolean> = {
    'Loaded Assets  ': preload,
    'Host connected ': host,
    'Storage ready  ': firebase,
    'Creation ID    ': creationId,
    'Product data   ': productData,
  }

  // Application is ready to receive product data
  const isReady = [firebase, creationId].every((v) => v === true)

  // Application is loading
  const isLoading = Object.values(conditions).some((v) => v === false)

  useEffect(() => {
    if (!isConnectedToHost) return // Do not send messages if not connected to the host
    if (!isReady) return // Do not send messages if not ready
    if (!hasVariants) return sendMessage({ type: IoMsgType.ready }) // Send ready message if there are no variants
    sendMessage({ type: IoMsgType.variantReady }) // Send variant ready message if there are variants
  }, [isConnectedToHost, isReady, hasVariants, sendMessage])

  const conditionsRender = () => (
    <pre className='conditions'>
      {Object.entries(conditions).map((entry) => (
        <code key={entry[0]}>
          {entry[0]}: {entry[1] ? '✅' : '❌'}
        </code>
      ))}
    </pre>
  )

  if (isLoading) {
    if (hasFetchID && !foundCreation) {
      return (
        <div className='loading'>
          <div className='brand' />
          <pre className='version'>V{version} Beta</pre>
          <div className='loader'>
            <div className='spinner' />
          </div>
          <pre>Cannot load the creation</pre>
        </div>
      )
    }

    if (loadingTimeout) {
      return (
        <div className='loading'>
          <div className='brand' />
          <pre className='version'>V{version} Beta</pre>
          <div className='loader'>
            <div className='spinner' />
          </div>
          <pre>Application load error, please refresh to try again.</pre>
          {conditionsRender()}
        </div>
      )
    }

    return (
      <div className='loading'>
        <div className='brand' />
        <pre className='version'>V{version} Beta</pre>
        <div className='loader'>
          <div className='spinner' />
        </div>
        <pre>loading application</pre>
        {conditionsRender()}
      </div>
    )
  }

  return (
    <div className='application'>
      <PreviewContainer />
      <ControllersContainer sendMessage={sendMessage} />
    </div>
  )
}
