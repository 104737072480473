import React, { ReactElement } from 'react'
import { Button } from '../button'
import styles from './styles.module.scss'

interface ConfirmProps {
  onConfirm: () => void
  confirmLabel: string
  cancelLabel: string
  onCancel: () => void
  header?: string
  canConfirm?: boolean
  warning?: boolean
  children: ReactElement | ReactElement[]
}

export const Confirm: React.FC<ConfirmProps> = (props) => {
  const showConfirm = props.canConfirm !== false
  const isWarning = props.warning === true
  return (
    <div className={styles.container}>
      {props.header ? <h2>{props.header}</h2> : null}
      <div className={props.header ? styles.copy : styles.copyNoMargin}>{props.children}</div>
      <div className={styles.confirm}>
        <Button
          onClick={props.onCancel}
          label={props.cancelLabel}
          black={!showConfirm}
          white={showConfirm}
          small
        />

        {showConfirm ? (
          <Button onClick={props.onConfirm} label={props.confirmLabel} warning={isWarning} small />
        ) : null}
      </div>
    </div>
  )
}
