import React, { createContext, useEffect, useMemo, useState } from 'react'

import backgroundSrc from '../assets/preview-layers/preview-background.jpg'

import fiveAcrylicLandscapeUnit from '../assets/preview-layers/IO5-ACR-CVP-landscape-unit.png'
import fiveAcrylicLandscapeMatte from '../assets/preview-layers/IO5-ACR-CVP-landscape-matte.png'

import fiveAcrylicPortraitUnit from '../assets/preview-layers/IO5-ACR-CVP-portrait-unit.png'
import fiveAcrylicPortraitMatte from '../assets/preview-layers/IO5-ACR-CVP-portrait-matte.png'

import fiveBambooLandscapeUnit from '../assets/preview-layers/IO5-BAM-CVP-landscape-unit.png'
import fiveBambooLandscapeMatte from '../assets/preview-layers/IO5-BAM-CVP-landscape-matte.png'

import fiveBambooPortraitUnit from '../assets/preview-layers/IO5-BAM-CVP-portrait-unit.png'
import fiveBambooPortraitMatte from '../assets/preview-layers/IO5-BAM-CVP-portrait-matte.png'

import sevenAcrylicLandscapeUnit from '../assets/preview-layers/IO7-ACR-CVP-landscape-unit.png'
import sevenAcrylicLandscapeMatte from '../assets/preview-layers/IO7-ACR-CVP-landscape-matte.png'

import sevenAcrylicPortraitUnit from '../assets/preview-layers/IO7-ACR-CVP-portrait-unit.png'
import sevenAcrylicPortraitMatte from '../assets/preview-layers/IO7-ACR-CVP-portrait-matte.png'

import sevenBambooLandscapeUnit from '../assets/preview-layers/IO7-BAM-CVP-landscape-unit.png'
import sevenBambooLandscapeMatte from '../assets/preview-layers/IO7-BAM-CVP-landscape-matte.png'

import sevenBambooPortraitUnit from '../assets/preview-layers/IO7-BAM-CVP-portrait-unit.png'
import sevenBambooPortraitMatte from '../assets/preview-layers/IO7-BAM-CVP-portrait-matte.png'

import tenAcrylicLandscapeUnit from '../assets/preview-layers/IO10-ACR-CVP-landscape-unit-black.png'
import tenAcrylicLandscapeMatte from '../assets/preview-layers/IO10-ACR-CVP-landscape-matte-black.png'

import tenAcrylicPortraitUnit from '../assets/preview-layers/IO10-ACR-CVP-portrait-unit-black.png'
import tenAcrylicPortraitMatte from '../assets/preview-layers/IO10-ACR-CVP-portrait-matte-black.png'

import { IoMaterial, IoOrientation, IoSize } from '../constants'
import { Device, ScreenPosition } from '../interfaces'
import { useShallowTypedSelector } from '../store'
import { isEmptyArray } from '../utils'

export interface PreviewInterface {
  backgroundSrc: string
  matteSrc: string
  deviceSrc: string
  frame: ScreenPosition
  video: ScreenPosition
}

/* contexts */
export const FIVE_LANDSCAPE_BAMBOO: PreviewInterface = {
  backgroundSrc,
  matteSrc: fiveBambooLandscapeMatte,
  deviceSrc: fiveBambooLandscapeUnit,
  frame: {
    width: 595,
    height: 417,
    left: 955,
    top: 1050,
  },
  video: {
    width: 395,
    height: 222,
    left: 1055,
    top: 1148,
  },
}

export const FIVE_PORTRAIT_BAMBOO: PreviewInterface = {
  backgroundSrc,
  matteSrc: fiveBambooPortraitMatte,
  deviceSrc: fiveBambooPortraitUnit,
  frame: {
    width: 415,
    height: 587,
    left: 1043,
    top: 880,
  },
  video: {
    width: 218,
    height: 389,
    left: 1141,
    top: 978,
  },
}

export const FIVE_LANDSCAPE_ACRYLIC: PreviewInterface = {
  backgroundSrc,
  matteSrc: fiveAcrylicLandscapeMatte,
  deviceSrc: fiveAcrylicLandscapeUnit,
  frame: {
    width: 595,
    height: 418,
    left: 954,
    top: 1052,
  },
  video: {
    width: 397,
    height: 223,
    left: 1052,
    top: 1148,
  },
}

export const FIVE_PORTRAIT_ACRYLIC: PreviewInterface = {
  backgroundSrc,
  matteSrc: fiveAcrylicPortraitMatte,
  deviceSrc: fiveAcrylicPortraitUnit,
  frame: {
    width: 414,
    height: 590,
    left: 1043,
    top: 880,
  },
  video: {
    width: 220,
    height: 392,
    left: 1139,
    top: 997,
  },
}

export const SEVEN_LANDSCAPE_ACRYLIC: PreviewInterface = {
  backgroundSrc,
  matteSrc: sevenAcrylicLandscapeMatte,
  deviceSrc: sevenAcrylicLandscapeUnit,
  frame: {
    width: 780,
    height: 535,
    left: 860,
    top: 935,
  },
  video: {
    width: 551,
    height: 310,
    left: 974,
    top: 1048,
  },
}

export const SEVEN_PORTRAIT_ACRYLIC: PreviewInterface = {
  backgroundSrc,
  matteSrc: sevenAcrylicPortraitMatte,
  deviceSrc: sevenAcrylicPortraitUnit,
  frame: {
    width: 535,
    height: 784,
    left: 982,
    top: 686,
  },
  video: {
    width: 314,
    height: 559,
    left: 1092,
    top: 800,
  },
}

export const SEVEN_LANDSCAPE_BAMBOO: PreviewInterface = {
  backgroundSrc,
  matteSrc: sevenBambooLandscapeMatte,
  deviceSrc: sevenBambooLandscapeUnit,
  frame: {
    width: 782,
    height: 535,
    left: 860,
    top: 935,
  },
  video: {
    width: 559,
    height: 315,
    left: 969,
    top: 1044,
  },
}

export const SEVEN_PORTRAIT_BAMBOO: PreviewInterface = {
  backgroundSrc,
  matteSrc: sevenBambooPortraitMatte,
  deviceSrc: sevenBambooPortraitUnit,
  frame: {
    width: 530,
    height: 780,
    left: 985,
    top: 690,
  },
  video: {
    width: 314,
    height: 558,
    left: 1092,
    top: 801,
  },
}

export const TEN_LANDSCAPE_ACRYLIC: PreviewInterface = {
  backgroundSrc,
  matteSrc: tenAcrylicLandscapeMatte,
  deviceSrc: tenAcrylicLandscapeUnit,
  frame: {
    width: 1021,
    height: 680,
    left: 760,
    top: 790,
  },
  video: {
    width: 780,
    height: 439,
    left: 880,
    top: 911,
  },
}

export const TEN_PORTRAIT_ACRYLIC: PreviewInterface = {
  backgroundSrc,
  matteSrc: tenAcrylicPortraitMatte,
  deviceSrc: tenAcrylicPortraitUnit,
  frame: {
    width: 712,
    height: 1072,
    left: 890,
    top: 400,
  },
  video: {
    width: 466,
    height: 828,
    left: 1010,
    top: 522,
  },
}

export const PreviewContext = createContext<PreviewInterface>(SEVEN_LANDSCAPE_ACRYLIC)
PreviewContext.displayName = 'Preview'
export const PreviewProvider: React.FC = ({ children }) => {
  const [loadedImg, setLoadedImg] = useState<HTMLImageElement[]>([])
  const device = useShallowTypedSelector((state) => state.creation.device)

  const ctx = useMemo(() => asPct(pickContext(device)), [device])

  useEffect(() => {
    if (isEmptyArray(loadedImg)) {
      const images = preloadImages([
        SEVEN_LANDSCAPE_BAMBOO,
        SEVEN_PORTRAIT_BAMBOO,
        SEVEN_LANDSCAPE_ACRYLIC,
        SEVEN_PORTRAIT_ACRYLIC,
        FIVE_LANDSCAPE_ACRYLIC,
        FIVE_PORTRAIT_ACRYLIC,
        FIVE_LANDSCAPE_BAMBOO,
        FIVE_PORTRAIT_BAMBOO,
      ])
      setLoadedImg(images)
    }
  }, [loadedImg])

  return <PreviewContext.Provider value={ctx}>{children}</PreviewContext.Provider>
}

/**
 * Preload the images in the give context array
 * @param contexts - The contexts to preload
 */
function preloadImages(contexts: PreviewInterface[]) {
  return contexts
    .reduce((acc, ctx) => {
      acc.push(ctx.deviceSrc)
      acc.push(ctx.matteSrc)
      return acc
    }, [] as string[])
    .filter((v, i, a) => a.indexOf(v) === i)
    .map((src) => {
      const img = new Image()
      img.src = src
      return img
    })
}

/**
 * Returns a Preview context based on the size and orientation
 * @param size - Infinite Object Size
 * @param orientation - Infinite Object Orientation
 */
function pickContext(device: Device): PreviewInterface {
  if (
    device.size === IoSize.fiveInch &&
    device.material === IoMaterial.acrylic &&
    device.orientation === IoOrientation.landscape
  ) {
    return FIVE_LANDSCAPE_ACRYLIC
  }

  if (
    device.size === IoSize.fiveInch &&
    device.material === IoMaterial.acrylic &&
    device.orientation === IoOrientation.portrait
  ) {
    return FIVE_PORTRAIT_ACRYLIC
  }

  if (
    device.size === IoSize.fiveInch &&
    device.material === IoMaterial.bamboo &&
    device.orientation === IoOrientation.landscape
  ) {
    return FIVE_LANDSCAPE_BAMBOO
  }

  if (
    device.size === IoSize.fiveInch &&
    device.material === IoMaterial.bamboo &&
    device.orientation === IoOrientation.portrait
  ) {
    return FIVE_PORTRAIT_BAMBOO
  }

  if (
    device.size === IoSize.sevenInch &&
    device.material === IoMaterial.acrylic &&
    device.orientation === IoOrientation.landscape
  ) {
    return SEVEN_LANDSCAPE_ACRYLIC
  }

  if (
    device.size === IoSize.sevenInch &&
    device.material === IoMaterial.acrylic &&
    device.orientation === IoOrientation.portrait
  ) {
    return SEVEN_PORTRAIT_ACRYLIC
  }
  if (
    device.size === IoSize.sevenInch &&
    device.material === IoMaterial.bamboo &&
    device.orientation === IoOrientation.landscape
  ) {
    return SEVEN_LANDSCAPE_BAMBOO
  }

  if (
    device.size === IoSize.sevenInch &&
    device.material === IoMaterial.bamboo &&
    device.orientation === IoOrientation.portrait
  ) {
    return SEVEN_PORTRAIT_BAMBOO
  }

  if (device.size === IoSize.tenInch && device.orientation === IoOrientation.landscape) {
    return TEN_LANDSCAPE_ACRYLIC
  }

  if (device.size === IoSize.tenInch && device.orientation === IoOrientation.portrait) {
    return TEN_PORTRAIT_ACRYLIC
  }

  return SEVEN_LANDSCAPE_ACRYLIC
}

function asPct(int: PreviewInterface): PreviewInterface {
  return {
    ...int,
    frame: {
      width: int.frame.width / 2500,
      height: int.frame.height / 2500,
      left: (int.frame.left + int.frame.width / 2) / 2500,
      top: (int.frame.top + int.frame.height / 2) / 2500,
    },
    video: {
      width: int.video.width / 2500,
      height: int.video.height / 2500,
      left: int.video.left / 2500,
      top: int.video.top / 2500,
    },
  }
}
