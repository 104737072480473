/* eslint-disable @typescript-eslint/no-explicit-any */
import { ObjectLike } from '../interfaces'
import { isArray, isObject } from './typechecks'

const DEFAULT_CLONE_DEPTH = 20

/**
 * Returns an object containing all elements that differ between two objects.
 *
 * It works best when object2 originated by deep copying object1, then
 * changes were made to object2, and you want an object that would give you
 * the changes made to object1 which resulted in object2.
 *
 * @param object1 - The base object to compare to
 * @param object2 - The object to compare with
 * @param depth - An optional depth to prevent recursion
 *
 * @privateRemarks Shameless copied from 'node-config'
 *
 */

export function diffDeep(object1: ObjectLike, object2: ObjectLike, depth = DEFAULT_CLONE_DEPTH): ObjectLike {
  // Recursion check
  if (depth < 0) return {}

  const diff: ObjectLike = {}
  /*
   * Process each element from object2,
   * adding any element that's different from object 1.
   */
  for (const parm in object2) {
    const value1 = object1[parm]
    const value2 = object2[parm]
    if (value1 && value2 && isObject(value2)) {
      if (!equalsDeep(value1, value2)) {
        diff[parm] = diffDeep(value1, value2, depth - 1)
      }
    } else if (isArray(value1) && isArray(value2)) {
      if (!equalsDeep(value1, value2)) {
        diff[parm] = value2
      }
    } else if (value1 !== value2) {
      diff[parm] = value2
    }
  }

  // Return the diff object
  return diff
}

/**
 * Returns true if the given objects have the same data

 */
export function equalsDeep(object1: any, object2: any, depth = DEFAULT_CLONE_DEPTH): any {
  // Recursion detection
  if (depth < 0) return {}

  // Fast comparisons
  if (!object1 || !object2) {
    return false
  }
  if (object1 === object2) {
    return true
  }
  if (typeof object1 !== 'object' || typeof object2 !== 'object') {
    return false
  }

  // They must have the same keys.  If their length isn't the same
  // then they're not equal.  If the keys aren't the same, the value
  // comparisons will fail.
  if (Object.keys(object1).length !== Object.keys(object2).length) {
    return false
  }

  // Compare the values
  for (const prop in object1) {
    // Call recursively if an object or array
    if (object1[prop] && typeof object1[prop] === 'object') {
      if (!equalsDeep(object1[prop], object2[prop], depth - 1)) {
        return false
      }
    } else {
      if (object1[prop] !== object2[prop]) {
        return false
      }
    }
  }

  // Test passed.
  return true
}

/**
 * Returns true if the given object is empty
 */
export const isEmptyObject = (obj: any): boolean => isObject(obj) && !Object.keys(obj).length

export function asPlainObject(obj: any): any {
  return JSON.parse(JSON.stringify(obj))
}
