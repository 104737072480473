import { useCallback, useEffect, useRef, useState } from 'react'
import { IoMsgType, IS_EMBEDDED } from '../constants'
import { IoEvent } from '../interfaces'
import { actions, useDispatch } from '../store'
import { Debug, hasWindow, isIoMessage } from '../utils'

const debug = Debug('app:messages')

/**
 * Enables Iframe communication
 */
export function useMessages(): [(event: IoEvent) => void, boolean] {
  const dispatch = useDispatch()

  const origin = useRef('*')

  const [isConnected, setConnected] = useState<boolean>(false)

  const sendMessage = useCallback((event: IoEvent) => {
    const { payload } = event

    if (IS_EMBEDDED && window.parent) {
      if (payload) debug('event → %s', event.type, { payload })
      else debug('event → %s', event.type)
      window.parent.postMessage(event, origin.current)
      return
    }

    if (!IS_EMBEDDED) debug('Not Embedded')
    if (!window.parent) debug('Cannot find parent window')
  }, [])

  const receiveMessage = useCallback(
    (event: MessageEvent) => {
      if (!isIoMessage(event)) return

      const payload = event.data.payload
      if (payload) debug('event ← %s', event.data.type, { payload })
      else debug('event ← %s', event.data.type)

      switch (event.data.type) {
        case IoMsgType.pong:
          origin.current = event.origin
          debug('Connected to host: %s', origin.current)
          setConnected(true)
          break
        case IoMsgType.variantData:
          dispatch(actions.setVariantData(event.data.payload))
          break
        case IoMsgType.variantSelected:
          dispatch(actions.setVariantById(event.data.payload))
          break
        default:
          console.error('Unmanaged event', event.data.type)
          break
      }
    },
    [dispatch],
  )

  useEffect(() => {
    if (!hasWindow()) return () => undefined
    if (!IS_EMBEDDED) return () => undefined
    window.addEventListener('message', receiveMessage)
    return () => window.removeEventListener('message', receiveMessage)
  }, [receiveMessage])

  useEffect(() => {
    if (!hasWindow()) return
    if (!IS_EMBEDDED) return
    if (isConnected) return
    // Send a ping to the parent window to establish connection
    sendMessage({ type: IoMsgType.ping })
  }, [sendMessage, isConnected])

  return [sendMessage, isConnected]
}
