import { Middleware } from '../interfaces'
import { Debug } from '../utils'
import { State } from './reducers'
/** Strict Middleware Type */
type TypedMiddleware = Middleware<State>

/** Debuggers */
const debug = Debug('redux:action')
/**
 * Logs the interactions with the store.
 * @todo Only print the diff state
 */
export const logger: TypedMiddleware = (_store) => (next) => (action) => {
  const { type } = action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const payload = (action as any).payload

  if (payload != null) {
    debug('Dispaching: %s', type, { payload })
  } else {
    debug('Dispaching: %s', type)
  }

  return next(action)
}

/**
 * Wraps the action in a try/catch construct
 * @todo Send crashes somewhere
 */
export const crashReporter: TypedMiddleware = (_store) => (next) => (action) => {
  // eslint-disable-next-line no-useless-catch
  try {
    return next(action)
  } catch (err) {
    throw err
  }
}
