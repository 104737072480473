import { MOBILE_WIDTH } from '../constants'

/**
 * Returns true if the running context is a browser window
 */
export const hasWindow = (): boolean => window && typeof window === 'object'

/**
 * Returns true if the screensize is on the mobile space
 */
export const isMobile = (): boolean => (hasWindow() ? window.innerWidth <= MOBILE_WIDTH : false)

/**
 * Returns true if the current context has the given interface
 * @param val - interface we are looking for
 * @example `const canURL = inGlobal('URL')`
 */
export const inGlobal = (val: string): boolean => {
  if (hasWindow()) return val in window
  return val in globalThis
}
