import { combineReducers } from 'redux'
import shortid from 'shortid'
import { CreationState, Filter, IoMaterial, IoOrientation, IoSize, Loop, VERSION } from '../../constants'
import {
  ActionType,
  Creation,
  DateType,
  Device,
  TypedAction,
  UserId,
  UUID,
  versionType,
  VideoFile,
  VideoTransform,
} from '../../interfaces'

const uuid = shortid.generate()
const initialState: Creation = {
  uuid,
  readyState: CreationState.pristine,
  device: {
    size: IoSize.sevenInch,
    orientation: IoOrientation.portrait,
    material: IoMaterial.acrylic,
  },

  video: {
    original: null,
    optimized: null,
    thumbnail: null,
    originalUrl: null,
    optimizedUrl: null,
    thumbnailUrl: null,

    metadata: {
      size: { width: 0, height: 0 },
      fileSize: 0,
      duration: 0,
      codec: null,
    },
  },

  transform: {
    filter: Filter.none,
    loop: Loop.normal,
    startTime: 0,
    endTime: 1,
    scale: 1,
    translate: { x: 0, y: 0 },
    rotate: 0,
    speed: 1,
  },

  owner: null,

  appVersion: VERSION,
  createdAt: new Date(),
  modifiedAt: null,
}

function uuidReducer(state: UUID = initialState.uuid, action: TypedAction): UUID {
  switch (action.type) {
    /* Load creation */
    case ActionType.loadCreation:
      return action.payload.uuid
    /* Default */
    default:
      return state
  }
}

/** Application state  */
function readyStateReducer(state = initialState.readyState, action: TypedAction): CreationState {
  switch (action.type) {
    case ActionType.loadCreation:
      return action.payload.readyState
    case ActionType.selectVideo:
      return CreationState.localVideo
    case ActionType.resetVideo:
      return CreationState.pristine
    case ActionType.setOriginalResource:
      return CreationState.hasUploaded
    default:
      return state
  }
}

function deviceReducer(state: Device = initialState.device, action: TypedAction): Device {
  switch (action.type) {
    /* Load Creation */
    case ActionType.loadCreation:
      return action.payload.device
    /* Change the IO size */
    case ActionType.setSize:
      return { ...state, size: action.payload }
    /* Change the IO orientation */
    case ActionType.setOrientation:
      return { ...state, orientation: action.payload }
    /* Change the IO Material */
    case ActionType.setMaterial:
      return { ...state, material: action.payload }

    case ActionType.selectVideo:
      return {
        ...state,
        orientation: action.payload.metadata.orientation,
      }
    /* Default */
    default:
      return state
  }
}

function videoReducer(state: VideoFile = initialState.video, action: TypedAction): VideoFile {
  switch (action.type) {
    /* Reset to initial state */

    case ActionType.resetVideo:
      return initialState.video
    /* Load creation */
    case ActionType.loadCreation:
      return action.payload.video

    case ActionType.selectVideo:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          size: action.payload.metadata.size,
          fileSize: action.payload.metadata.fileSize,
          duration: action.payload.metadata.duration,
        },
      }
    case ActionType.setOriginalResource:
      return {
        ...state,
        original: action.payload,
      }
    /* Default */
    default:
      return state
  }
}

function transformReducer(
  state: VideoTransform = initialState.transform,
  action: TypedAction,
): VideoTransform {
  switch (action.type) {
    /* Reset to initial state */
    case ActionType.resetVideo:
      return initialState.transform
    /* Reset transforms */
    case ActionType.resetTransforms:
      return initialState.transform
    /* Load creation */
    case ActionType.loadCreation:
      return action.payload.transform
    /*  Change the filter */
    case ActionType.setFilter:
      return { ...state, filter: action.payload }
    /* Change rotation */
    case ActionType.setRotation:
      return { ...state, rotate: normalizeRotation(action.payload) }
    /* Change the video playback speed */
    case ActionType.setSpeed: // TODO: Clamp the speed to something like 0.5 - 2
      return { ...state, speed: action.payload }
    /* Translate video */
    case ActionType.setTranslation:
      return { ...state, translate: action.payload }
    /* Scale the video */
    case ActionType.setScale:
      return { ...state, scale: action.payload }
    /* Trim Video - we get a Tuple from the action */
    case ActionType.setTrim:
      return {
        ...state,
        startTime: action.payload[0],
        endTime: action.payload[1],
      }
    /* Change the loop */
    case ActionType.setLoop:
      return { ...state, loop: action.payload }

    /* Default */
    default:
      return state
  }

  function normalizeRotation(payload: { angle: number; add?: boolean; clamp?: boolean }): number {
    let { add: addRotation, clamp: clampRotation, angle } = payload
    if (addRotation) angle = angle + state.rotate
    if (clampRotation) {
      angle = (angle + 360) % 360
      if (angle >= 0 && angle < 90) {
        angle = 0
      } else if (angle >= 90 && angle < 180) {
        angle = 90
      } else if (angle >= 180 && angle < 270) {
        angle = 180
      } else if (angle >= 270 && angle < 360) {
        angle = -90
      } else {
        angle = -180
      }
    }
    return angle
  }
}

function ownerReducer(state: UserId = initialState.owner, action: TypedAction): UserId {
  switch (action.type) {
    /* Load creation */
    case ActionType.loadCreation:
      return action.payload.owner
    /* Do not change the owner if is already set */
    case ActionType.setUser:
      return state || (action.payload ? action.payload.uid : state)
    /* Default */
    default:
      return state
  }
}

function versionReducer(state: versionType = initialState.appVersion, action: TypedAction): versionType {
  switch (action.type) {
    /* HYDRO HOMIES */
    case ActionType.loadCreation:
      if (action.payload.appVersion !== initialState.appVersion) {
        // eslint-disable-next-line no-console
        console.warn('Data was created in a different app version.')
      }
      return action.payload.appVersion

    default:
      return state
  }
}

function createdAtReducer(state: DateType = initialState.createdAt, action: TypedAction): DateType {
  switch (action.type) {
    /* Load creation */
    case ActionType.loadCreation:
      return action.payload.createdAt
    default:
      return state
  }
}

function modifiedAtReducer(state: DateType = initialState.modifiedAt, action: TypedAction): DateType {
  switch (action.type) {
    /* Load creation */
    case ActionType.loadCreation:
      return action.payload.modifiedAt
    default:
      return state
  }
}

export const creationReducer = combineReducers<Creation>({
  uuid: uuidReducer,
  readyState: readyStateReducer,
  device: deviceReducer,
  video: videoReducer,
  transform: transformReducer,
  owner: ownerReducer,
  appVersion: versionReducer,
  createdAt: createdAtReducer,
  modifiedAt: modifiedAtReducer,
})
