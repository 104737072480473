import { MutableRefObject, useEffect, useRef } from 'react'
import { useDebug } from '../utils'

export const useEventListener = <K extends keyof HTMLElementEventMap>(
  eventName: K,
  handler: EventListener,
  elemReference: MutableRefObject<HTMLVideoElement | null>,
): void => {
  const debug = useDebug('video:event')
  const savedHandler = useRef<EventListener>(console.error)

  useEffect(() => {
    savedHandler.current = handler
  }, [handler])

  useEffect(() => {
    const { current: element } = elemReference

    if (!isElem(element)) return

    const eventListener = (event: Event) => savedHandler.current(event)
    debug('Adding %s', eventName)
    element.addEventListener(eventName, eventListener)
    return () => {
      debug('Removing %s', eventName)
      element.removeEventListener(eventName, eventListener)
    }
  }, [debug, elemReference, eventName])
}

function isElem(elem: HTMLVideoElement | null): elem is HTMLVideoElement {
  return !!(elem && elem.addEventListener)
}
