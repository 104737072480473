import React from 'react'
import { asClassName } from '../../utils'
import styles from './filter-image.module.scss'

interface FilterImageProps {
  isSelected: boolean
  onClick: () => void
  src: string
  label: string
}
export const FilterImage: React.FC<FilterImageProps> = (props) => {
  return (
    <div
      className={asClassName(styles.filter, props.isSelected ? styles.selected : null)}
      onClick={props.onClick}
    >
      <div className={styles.image} style={{ backgroundImage: `url(${props.src})` }}></div>
      <div className={styles.label}>{props.label}</div>
    </div>
  )
}
