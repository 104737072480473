import React from 'react'
import { useTypedSelector } from '../../store'
import styles from './control-group.module.scss'

interface ControlGroupProps {
  labelOnMobile?: boolean
  label: string | React.ReactNode
}

interface OptionsGroupProps {
  verticalOnDesktop?: boolean
}

export const ControlGroup: React.FC<ControlGroupProps> = (props) => {
  const isDesktop = useTypedSelector((state) => !state.local.isMobile)
  const useLabel = props.labelOnMobile || isDesktop
  return (
    <div className={styles.control}>
      {useLabel ? <h2>{props.label}</h2> : <React.Fragment />}
      {props.children}
    </div>
  )
}

export const OptionsGroup: React.FC<OptionsGroupProps> = (props) => {
  const isDesktop = useTypedSelector((state) => !state.local.isMobile)
  const useVertical = props.verticalOnDesktop && isDesktop
  return <div className={useVertical ? styles.optionsVertical : styles.options}>{props.children}</div>
}
