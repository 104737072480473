import { IoMaterial, IoSize } from '../../constants'
import type { Nullable } from '../utils'
import type { IInteger, ShopifyResourceId } from './common'
import type { IMediaBasic, IMediaImage, IMediaItem, IMediaVideo } from './media-item'
import type { IMetaFieldWeight } from './metafields'
import type { IPackagingContent, IUnitType } from './unit-type'
import type { IArtworkSpecs, IProductArtwork } from './artwork-specs'
import type { ITechnicalSpecs } from './technical-specs'
import type { IVariantShipping } from './variants-shipping'

/**
 * Shopify Variant Inventory Policy
 * @public
 */
export enum InventoryPolicy {
  deny = 'deny',
  continue = 'continue',
}

/**
 * Sku Device Sizes
 * @public
 *
 */
export const SKU_SIZES = [
  'IO5', // Five Inch device
  'IO7', // Seven Inch device
  'IO10', // Ten Inch device
  'IO26', // Twenty-Six Inch device
] as const

/**
 * Sku Device Materials
 * @public
 */
export const SKU_MATERIALS = [
  'BAM', // Bamboo
  'ACR', // Acrylic
] as const

// ───────────────────────────────  SKU REGEX  ─────────────────────────────────

const SIZE_REGEX = `(${SKU_SIZES.join('|')})` as const
const MATERIAL_REGEX = `(${SKU_MATERIALS.join('|')})` as const

/**
 * SKU Parse String
 * @public
 *
 * @privateRemarks
 * This is a simplified version of the SKU parser from the data-types library.
 */
export const SKU_REGEX_STRING = `^${SIZE_REGEX}-${MATERIAL_REGEX}` as const

// ────────────────────────────────  Variant  ──────────────────────────────────

/**
 * Variant information
 * @public
 *
 * @remarks
 * This is the fully qualified variant object
 */
export interface IVariant {
  /** The ID of the variant. */
  id: ShopifyResourceId

  /** A concatenation of each variant option, separated by a /. */
  title: string

  /** The value of the variant for the first product option. */
  option1: Nullable<string>

  /** The value of the variant for the second product option. */
  option2: Nullable<string>

  /** The value of the variant for the third product option. */
  option3: Nullable<string>

  /** The SKU of the variant. */
  sku: Nullable<string>

  /** Available to purchase? */
  available: boolean

  /** Price of the variant with currency */
  price: string

  /** Price of the product without currency (number) */
  priceValue: number

  /** Compare at price of the variant with currency */
  compareAtPrice: string

  /** Compare at price of the variant without currency (number) */
  compareAtPriceValue: number

  /** True if the variant is on sale (compare \> price) */
  onSale: boolean

  /** Variant media */
  media: IVariantMedia

  /** The weight of the variant in the unit specified  */
  weight: IMetaFieldWeight

  /** Inventory information */
  inventory: IVariantInventory
  /** Shipping information */
  shipping: IVariantShipping

  /** Packaging contents */
  packagingContents: IPackagingContent[]

  /** Technical specifications */
  technicalSpecs: ITechnicalSpecs

  /** Artwork information */
  artworkSpecs: IArtworkSpecs

  /** Print ID */
  printId: Nullable<string>

  /** Unit type */
  unitType: Nullable<IUnitType>
}

export interface IVariantSizeMaterial extends IVariant {
  size: IoSize
  material: IoMaterial
}

// ────────────────────────────────  Artwork  ──────────────────────────────────

/**
 * Metafields information from the 'artwork' namespace
 * @public
 */
export interface IVariantArtwork {
  printId: Nullable<string>
  unitType: Nullable<IUnitType>
}

// ─────────────────────────────────  Media  ───────────────────────────────────

/**
 * Variant Media
 * @public
 */
export interface IVariantMedia {
  /**
   * Poster image of the variant
   * @remarks
   * - This is the image attached to the variant
   */
  poster: Nullable<IMediaImage>

  /** Video of the product in place */
  inSitu: Nullable<IMediaVideo>

  /** Front view */
  frontView: Nullable<IMediaVideo>

  /** Back view */
  backView: Nullable<IMediaBasic>
}

// ───────────────────────────────  Inventory  ─────────────────────────────────

/**
 * Inventory information
 * @public
 */
export interface IVariantInventory {
  policy: string
  tracked: boolean
  quantity: IInteger
}

// ────────────────────────  Transformation Context  ───────────────────────────

/**
 * Transformation context
 * @remarks
 * This is the context needed to create a `IVariant` object
 */
export interface IVariantContext {
  /** Published date of the product */
  publishedDate: Date

  /**
   * Isolated media
   * @remarks
   * This is the isolated front video.
   */
  thumbnail: Nullable<IMediaItem>

  /** Artwork */
  artwork: IProductArtwork
}

// ──────────────────────────────────  SKU  ────────────────────────────────────

/**
 * Size Material Tuple
 */
export type SizeMaterial = [size: IoSize, material: IoMaterial]

class Assert {
  static notNull<T>(value: T | null): value is T {
    return value !== null
  }

  static notNill<T>(value: T | null | undefined): value is T {
    return value !== null && value !== undefined
  }
}

/**
 * Utilities to work with Variant data
 * @public
 *
 * @privateRemarks
 * The methods should not require a product to be passed in.
 */
export class VariantUtils {
  /**
   * Returns an array of all the media from a variant
   * @remarks
   * It will filter out any null values
   * @param variant - A variant to get the media from
   */
  static getMedia(variant: IVariant): IMediaItem[] {
    return Object.values(variant.media).filter(Assert.notNill)
  }

  /**
   * Returns an array of all the display media from a variant
   * @remarks
   * A display media is all the media minus the poster image.
   */
  static getDisplayMedia(variant: IVariant): IMediaItem[] {
    const { poster, ...media } = variant.media
    return Object.values(media).filter(Assert.notNill)
  }

  /**
   * Returns true if the variant is a video Print
   * @remarks
   * A video print is a variant with a valid SKU that includes size and material
   */
  static isVideoPrint(variant: IVariant): boolean {
    if (!variant.sku) return false
    const skuTuple = VariantUtils.getSizeAndMaterial(variant)
    return skuTuple !== null
  }

  /**
   * Returns the size and material from a variant
   * @remarks
   * Size and material are extracted from the SKU and returned as-is
   * without any further casting to Device Sizes or Materials.
   */
  static getSizeAndMaterial(variant: IVariant): Nullable<SizeMaterial> {
    if (!variant.sku) return null

    const regex = new RegExp(SKU_REGEX_STRING)
    const results = regex.exec(variant.sku)

    if (results) {
      const [, size, material] = results

      const asSize: IoSize =
        size === 'IO10' // IO10
          ? IoSize.tenInch
          : size === 'IO7' // IO7
            ? IoSize.sevenInch
            : size === 'IO5' // IO5
              ? IoSize.fiveInch
              : IoSize.sevenInch // Default to 7 Inch

      const asMaterial: IoMaterial =
        material === 'ACR' // ACRYLIC
          ? IoMaterial.acrylic
          : material === 'BAM' // BAMBOO
            ? IoMaterial.bamboo
            : IoMaterial.acrylic // Default to Acrylic

      return [asSize, asMaterial]
    }

    return null
  }

  static toSizeMaterial(variant: IVariant): IVariantSizeMaterial {
    const sizeMaterial = VariantUtils.getSizeAndMaterial(variant)
    if (!sizeMaterial) {
      console.error('Variant does not have a valid size and material')
      return {
        ...variant,
        size: IoSize.sevenInch,
        material: IoMaterial.acrylic,
      }
    }

    return {
      ...variant,
      size: sizeMaterial[0],
      material: sizeMaterial[1],
    }
  }
}
