import { shallowEqual, TypedUseSelectorHook, useDispatch as dispatch, useSelector } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunk, { ThunkDispatch } from 'redux-thunk'
import { TypedAction } from '../interfaces'
import * as actions from './actions'
import { crashReporter, logger } from './middleware'
import { rootReducer, State } from './reducers'

export type { State }
export { actions }
export type Store = ReturnType<typeof initializeStore>

export const useDispatch = (): ThunkDispatch<State, undefined, TypedAction> =>
  dispatch<ThunkDispatch<State, undefined, TypedAction>>()

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const initializeStore = (initialState = {}) =>
  createStore(rootReducer, initialState, composeWithDevTools(applyMiddleware(thunk, logger, crashReporter)))

export const useTypedSelector: TypedUseSelectorHook<State> = useSelector

/**
 * Creates a selector suitable for accessing objects without unnecessary re-render of the component
 */
export const useShallowTypedSelector: TypedUseSelectorHook<State> = (selector) =>
  useSelector(selector, shallowEqual)
