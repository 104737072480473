import React, { useMemo } from 'react'
import {
  AppState,
  IoOrientation,
  IoSize,
  IO_HEIGHT_10,
  IO_HEIGHT_5,
  IO_HEIGHT_7,
  IO_WIDTH_10,
  IO_WIDTH_5,
  IO_WIDTH_7,
} from '../../constants'
import { Size } from '../../interfaces'
import { useTypedSelector } from '../../store'
import { useDebug } from '../../utils'
import styles from './size-warning.module.scss'

export const SizeWarning: React.FC = () => {
  const debug = useDebug('resWarning')
  /** Application State */
  const readyState = useTypedSelector((state) => state.readyState)
  /** Video real size */
  const videoSize = useTypedSelector((s) => s.creation.video.metadata.size)
  /** Selected SKU size */
  const size = useTypedSelector((state) => state.creation.device.size)
  /** Selected SKU orientation */
  const orientation = useTypedSelector((s) => s.creation.device.orientation)
  /** Creation scale 0-1 rage */
  const scale = useTypedSelector((state) => state.creation.transform.scale)

  /** Memoized boolean */
  const showWarning = useMemo(() => {
    /* Only show when editing */
    if (readyState < AppState.editingCreation || readyState === AppState.previewCreation) return false

    /* Get real pixel size of the IO and the video */
    const vidSize = getVideoSize(videoSize, scale)
    const ioSize = getIoSize(size, orientation)
    /* If the vidSize is smaller than the ioSize return true */
    return vidSize.height < ioSize.height || vidSize.width < ioSize.width
  }, [orientation, readyState, scale, size, videoSize])

  /* Print a message if should show the warning */
  if (showWarning) debug('Resolution warning')

  /* Render the component  */
  return (
    <div className={styles.container}>
      <div className={showWarning ? styles.content : styles.hidden}>⚠️ Warning: Low resolution</div>
    </div>
  )
}

/**
 * Returns the size of the Video in pixel space
 * @param videoSize - Video Upload size
 * @param scale - Current scale factor
 */
function getVideoSize(videoSize: Size, scale: number): Size {
  return {
    width: videoSize.width / scale,
    height: videoSize.height / scale,
  }
}

/**
 * Returns the size of the IO in pixel space
 * @param size - Infinite Object Size
 * @param orientation - Infinite Object Orientation
 */
function getIoSize(size: IoSize, orientation: IoOrientation): Size {
  if (size === IoSize.fiveInch && orientation === IoOrientation.landscape) {
    return { width: IO_WIDTH_5, height: IO_HEIGHT_5 }
  }

  if (size === IoSize.fiveInch && orientation === IoOrientation.portrait) {
    return { width: IO_HEIGHT_5, height: IO_WIDTH_5 }
  }

  if (size === IoSize.sevenInch && orientation === IoOrientation.landscape) {
    return { width: IO_WIDTH_7, height: IO_HEIGHT_7 }
  }

  if (size === IoSize.sevenInch && orientation === IoOrientation.portrait) {
    return { width: IO_HEIGHT_7, height: IO_WIDTH_7 }
  }

  if (size === IoSize.tenInch && orientation === IoOrientation.landscape) {
    return { width: IO_WIDTH_10, height: IO_HEIGHT_10 }
  }

  if (size === IoSize.tenInch && orientation === IoOrientation.portrait) {
    return { width: IO_HEIGHT_10, height: IO_WIDTH_10 }
  }

  return { width: IO_WIDTH_7, height: IO_HEIGHT_7 }
}
