import firebase from 'firebase/compat/app'
// import tap from 'p-tap'
import { useEffect, useState } from 'react'
import semver from 'semver'
import {
  CreationState,
  FIREBASE_COLLECTION,
  IoMaterial,
  MIN_NEW_VERSION,
  USE_EMULATORS,
  VERSION,
} from '../constants'
import { Creation, OldCreation, User } from '../interfaces'
import { Debug, timeDiff } from '../utils'
import './firebase-import'

// ─────────────────────────────────  Types  ───────────────────────────────────
type MixedCreation = Creation | OldCreation

// ─────────────────────────────────  Hook  ────────────────────────────────────
export function useFirebase(): boolean {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (loaded) return
    initializeFirebase().then(() => setLoaded(true))
  }, [loaded])
  return loaded
}

// ────────────────────────────────  Methods  ──────────────────────────────────
export async function initializeFirebase(): Promise<firebase.app.App> {
  const debug = Debug('firebase:init')

  return fetch('/__/firebase/init.json')
    .then((response) => response.json())
    .then((data) => firebase.initializeApp(data))
    .then((app) => configureApp(app))

  async function configureApp(App: firebase.app.App) {
    debug('Successfully connected.')

    const SUPPORTS_ANALYTICS = await firebase.analytics.isSupported()

    if (SUPPORTS_ANALYTICS) {
      try {
        App.analytics()
        debug('Analytics initialized')
        App.performance()
        debug('Performance Tracking initialized')
      } catch {
        console.error('Cannot initialize analytics')
      }
    }

    if (USE_EMULATORS === false) return App
    debug('Development environment detected, using emulated services')
    App.firestore().settings({
      host: `${window.location.hostname}:8080`,
      ssl: false,
    })
    debug('Using Firestore Emulator at port 8080')

    return App
  }
}

export const storeCreation = async (creation: Creation, user: User): Promise<boolean> => {
  const debug = Debug('firebase:store')

  if (!user) {
    debug('Has not logged in')
    return Promise.resolve(false)
  }
  // TODO: #111 Admin users should be able to override the write permissions
  if (user.uid !== creation.owner) {
    debug('User and creation owner are different')
    return Promise.resolve(false)
  }

  const ref = firebase
    .firestore()
    .collection(FIREBASE_COLLECTION)
    .withConverter(CreationConverter(debug))
    .doc(creation.uuid)

  const exists = await ref.get().then((doc) => doc.exists)

  if (!exists) {
    debug('Creating document %s', creation.uuid)

    return ref
      .set(creation)
      .then(() => true)
      .catch((err) => {
        console.error(err)
        return false
      })
      .finally(() => debug('done'))
  }

  debug('Updating document %s', creation.uuid)

  const mergeFields = needsDowngrade(creation) ? ['video'] : ['device', 'transform', 'modifiedAt']
  debug('Merge fields %o', mergeFields)

  return ref
    .set(creation, { mergeFields })
    .then(() => true)
    .catch((err) => {
      console.error(err)
      return false
    })
    .finally(() => debug('done'))
}

export const logInToFirebase = async (credentials?: {
  email: string
  password: string
}): Promise<firebase.User | null> => {
  const debug = Debug('firebase:auth')
  // TODO: #112 Log in with admin credentials
  if (credentials) {
    return firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(processUser)
  }
  return firebase.auth().signInAnonymously().then(processUser)

  function processUser(data: firebase.auth.UserCredential) {
    if (data.user) {
      if (data.user.isAnonymous) debug('Logged as anonymous user')
      debug('Userid: %s', data.user.uid)
      return data.user
    }
    debug('Cannot log in with the given credentials')
    return null
  }
}

export const getCreationById = async (id: string): Promise<Creation | null> => {
  const debug = Debug('firebase:store')
  debug('Fetching Creation: %s', id)
  const creation = await firebase
    .firestore()
    .collection(FIREBASE_COLLECTION)
    .withConverter(CreationConverter(debug))
    .doc(id)
    .get()
    .then(async (doc) => {
      if (!doc || !doc.exists) return null
      const data = await doc.data()
      if (!data) return null
      return data
    })

  if (!creation) {
    debug('Cannot find creation: %s', id)
    return creation
  }

  return creation
}

// ─────────────────────────────────  Utils  ───────────────────────────────────

/** Get the version of the creation */
function getVersion(data: MixedCreation) {
  const version = 'v' in data ? data.v : 'appVersion' in data ? data.appVersion : null
  if (!version || !semver.valid(version)) {
    // eslint-disable-next-line no-console
    console.warn(`Invalid version (${version})`)
    return '0.0.0'
  }
  return version
}
/** Returns true if the creation needs to be downgraded */
function needsDowngrade(data: MixedCreation) {
  const version = getVersion(data)
  return semver.lt(version, MIN_NEW_VERSION)
}

/** Returns a FirestoreDataConverter */
function CreationConverter(debug: debug.Debugger) {
  return {
    toFirestore(data: Creation): firebase.firestore.DocumentData {
      debug('Running to Firestore')
      data.modifiedAt = new Date()
      const creation = needsDowngrade(data) ? downgradeSchema(data) : data
      debug('↑ Creation %s data: %o', creation.uuid, creation)
      return creation
    },
    fromFirestore(
      snapshot: firebase.firestore.QueryDocumentSnapshot,
      options: firebase.firestore.SnapshotOptions,
    ): Creation {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const data = snapshot.data(options)! as MixedCreation
      const version = getVersion(data)
      if (semver.neq(version, VERSION)) {
        debug('Creation made on a diffent app version (%s)', version)
      }
      const creation = isOldCreation(data) ? upgradeSchema(data) : data
      debug('↓ Creation %s data: %o', creation.uuid, creation)
      return creation
    },
  }

  /** Upgrades the Creation schema */
  function upgradeSchema(data: OldCreation): Creation {
    debug('Upgrading Creation')
    const now = new Date()

    if (!data.video.ioMaterial) debug('No material found, defaulting to %s', IoMaterial.acrylic)
    if (!data.cloudinary.isProcessed) debug('Creation has not been processed by Cloudinary')
    if (!data.cloudinary.fileSize) debug('Creation has no data for "cloudinary.fileSize", defaulting to 0')

    const creation = {
      uuid: data.uuid,
      owner: data.owner || null,

      readyState: data.cloudinary.isProcessed ? CreationState.hasDerivedVideos : CreationState.hasUploaded,

      appVersion: data.v,

      device: {
        size: data.video.ioSize,
        orientation: data.video.ioOrientation,
        material: data.video.ioMaterial || IoMaterial.acrylic,
      },

      video: {
        originalUrl: data.cloudinary.originalVideo,
        optimizedUrl: data.cloudinary.optimizedVideo,
        thumbnailUrl: data.cloudinary.thumbnailUrl,
        original: null,
        optimized: null,
        thumbnail: null,

        metadata: {
          size: data.video.videoSize,
          fileSize: data.cloudinary.fileSize || 0,
          duration: data.video.videoDuration,
          codec: data.cloudinary.originalCodec,
        },
      },

      transform: {
        filter: data.video.filter,
        loop: data.video.loop,
        startTime: data.video.startTime / data.video.videoDuration,
        endTime: data.video.endTime / data.video.videoDuration,
        scale: data.video.scale,
        translate: data.video.translate,
        rotate: data.video.rotate,
        speed: data.video.speed,
      },

      createdAt: data.cloudinary.timers.startedAt ? new Date(data.cloudinary.timers.startedAt) : now,

      modifiedAt: data.shopify?.createdAt ? new Date(data.shopify.createdAt) : now,
    }

    return creation
  }

  /** Downgrades the Creation Schema  */
  function downgradeSchema(data: Creation): OldCreation {
    debug('Downgrading Creation')

    if (data.readyState >= CreationState.hasUploaded)
      debug('Video has been uploaded, setting "editable" as true')

    if (data.readyState >= CreationState.hasDerivedVideos)
      debug('Found optimized videos, setting "cloudinary.isProcessed" as true')

    const creation = {
      uuid: data.uuid,
      owner: data.owner,
      editable: data.readyState >= CreationState.hasUploaded,
      video: {
        ioSize: data.device.size,
        ioOrientation: data.device.orientation,
        ioMaterial: data.device.material,
        filter: data.transform.filter,
        loop: data.transform.loop,
        videoSize: data.video.metadata.size,
        videoDuration: data.video.metadata.duration,
        startTime: data.transform.startTime * data.video.metadata.duration,
        endTime: data.transform.endTime * data.video.metadata.duration,
        scale: data.transform.scale,
        translate: data.transform.translate,
        rotate: data.transform.rotate,
        speed: data.transform.speed,
      },

      cloudinary: {
        id: null,
        thumbnailUrl: data.video.thumbnailUrl,
        originalVideo: data.video.originalUrl,
        originalCodec: data.video.metadata.codec,
        optimizedVideo: data.video.optimizedUrl,
        isProcessed: data.readyState >= CreationState.hasDerivedVideos,
        fileSize: data.video.metadata.fileSize,
        timers: {
          startedAt: data.createdAt,
          completedAt: data.modifiedAt,
          processTime: timeDiff(data.createdAt, data.modifiedAt),
        },
      },

      v: data.appVersion,
    }

    return creation
  }

  /** Returns true if the creation was made in an older app */
  function isOldCreation(data: MixedCreation): data is OldCreation {
    return 'v' in data && data.v != null
  }
}
