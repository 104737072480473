import Slider, { Range } from 'rc-slider'
import React, { useEffect, useState } from 'react'
import imageGrayscale from '../../assets/filter-images/filter-grayscale.png'
import imageNone from '../../assets/filter-images/filter-none.png'
import imageSepia from '../../assets/filter-images/filter-sepia.png'
import imageVivid from '../../assets/filter-images/filter-vivid.png'
import { ReactComponent as ResetIcon } from '../../assets/icons/reset.svg'
import { ReactComponent as RotateIcon } from '../../assets/icons/rotate.svg'
import { ReactComponent as SpeedIcon } from '../../assets/icons/speed.svg'
import { ReactComponent as TrimIcon } from '../../assets/icons/trim.svg'
import { ReactComponent as ZoomIcon } from '../../assets/icons/zoom.svg'
import { Button } from '../../components/button'
import { Swiper } from '../../components/swiper'
import { ControlGroup, OptionsGroup } from '../../components/control-group'
import { TabButton, TabContainer, TabNavigation } from '../../components/control-tab'
import { EditControl, EditTabButton, EditTabsContainer, HackContainer } from '../../components/edit-controls'
import { FilterImage } from '../../components/filter-image'
import { RadioButton, VariantButton } from '../../components/radio-button'
import { RotateButton } from '../../components/rotate-button'
import { ResetLabel, SizeLabel } from '../../components/size-label'
import { EditTabs, Filter, IoMsgType, IoOrientation, Tabs } from '../../constants'
import { IoEvent, Variant } from '../../interfaces'
import { actions, useDispatch, useShallowTypedSelector, useTypedSelector } from '../../store'
import styles from './styles.module.scss'
import { ShipsIn } from '../../components/ships-in'

interface EditProps {
  sendMessage: (event: IoEvent) => void
  onFinish: () => void
}

export const EditContainer: React.FC<EditProps> = (props) => {
  const [selectedTab, selectTab] = useState(Tabs.size)
  const [selectedEdit, selectEdit] = useState(EditTabs.speed)

  // const [hasConfirmed, setConfirmation] = useState(false)
  const sendMessage = props.sendMessage
  const dispatch = useDispatch()

  /* Get values from video transform */
  const startTime = useTypedSelector((state) => state.creation.transform.startTime)
  const endTime = useTypedSelector((state) => state.creation.transform.endTime)
  const rotate = useTypedSelector((state) => state.creation.transform.rotate)
  const scale = useTypedSelector((state) => state.creation.transform.scale)
  const speed = useTypedSelector((state) => state.creation.transform.speed)
  const filter = useTypedSelector((state) => state.creation.transform.filter)
  /* Get values from Device Selection */
  const size = useTypedSelector((state) => state.creation.device.size)
  const material = useTypedSelector((state) => state.creation.device.material)
  const orientation = useTypedSelector((state) => state.creation.device.orientation)

  const variants = useShallowTypedSelector((state) => state.shopify.variants)
  const uuid = useTypedSelector((state) => state.creation.uuid)

  // ───────────────────────────────  Effect  ──────────────────────────────────

  useEffect(() => {
    sendMessage({ type: IoMsgType.setCreationId, payload: uuid })
  }, [sendMessage, uuid])

  // ───────────────────────────────  Actions  ─────────────────────────────────

  const updateTrim = (val: number[]) => {
    const values = val.map((v) => v / 100)
    const [start, end] = values as [number, number]

    if (startTime !== start) dispatch(actions.setCurrentTime(start))
    if (endTime !== end) dispatch(actions.setCurrentTime(end))
    dispatch(actions.setTrim([start, end]))
  }

  const updateTrimState = (val: boolean) => dispatch(actions.isTrimming(val))
  const updateRotation = (val: number) => dispatch(actions.setRotation(val))
  const updateScale = (val: number) => dispatch(actions.setScale(val))
  const updateSpeed = (val: number) => dispatch(actions.setSpeed(val))
  const changeOrientation = (val: IoOrientation) => () => dispatch(actions.setOrientation(val))
  const changeFilter = (val: Filter) => () => dispatch(actions.setFilter(val))

  const changeVariant = (variant: Variant) => () => {
    dispatch(actions.setShipsIn(variant.shipping.shipsIn))
    dispatch(actions.setSize(variant.size))
    dispatch(actions.setMaterial(variant.material))
    sendMessage({ type: IoMsgType.variantSelected, payload: variant.id })
  }

  return (
    <React.Fragment>
      <div className={styles.controllers}>
        <TabNavigation>
          <TabButton isActive={selectedTab === Tabs.size} onClick={() => selectTab(Tabs.size)} label='size' />
          <TabButton
            isActive={selectedTab === Tabs.filter}
            onClick={() => selectTab(Tabs.filter)}
            label='filter'
          />
          <TabButton isActive={selectedTab === Tabs.edit} onClick={() => selectTab(Tabs.edit)} label='edit' />
        </TabNavigation>
        <TabContainer isActive={selectedTab === Tabs.size}>
          <ControlGroup
            labelOnMobile={true}
            label={<SizeLabel onClick={() => sendMessage({ type: IoMsgType.modalOpen })} />}
          >
            <OptionsGroup>
              <Swiper spaceBetween={20}>
                {variants
                  .filter((v) => v.available)
                  .map((variant, idx, arr) => {
                    return (
                      <VariantButton
                        key={idx}
                        total={arr.length}
                        fixWidth={95}
                        onClick={changeVariant(variant)}
                        isSelected={size === variant.size && material === variant.material}
                        variant={variant}
                      />
                    )
                  })}
              </Swiper>
            </OptionsGroup>
          </ControlGroup>
          <ControlGroup label='orientation' labelOnMobile>
            <OptionsGroup>
              <RadioButton
                onClick={changeOrientation(IoOrientation.portrait)}
                isSelected={orientation === IoOrientation.portrait}
                label='Portrait'
              />
              <RadioButton
                onClick={changeOrientation(IoOrientation.landscape)}
                isSelected={orientation === IoOrientation.landscape}
                label='Landscape'
              />
            </OptionsGroup>
          </ControlGroup>
        </TabContainer>
        <TabContainer isActive={selectedTab === Tabs.filter}>
          <ControlGroup label='filter'>
            <OptionsGroup>
              <FilterImage
                onClick={changeFilter(Filter.none)}
                isSelected={filter === Filter.none}
                src={imageNone}
                label='None'
              />
              <FilterImage
                onClick={changeFilter(Filter.blackWhite)}
                isSelected={filter === Filter.blackWhite}
                src={imageGrayscale}
                label='B&W'
              />
              <FilterImage
                onClick={changeFilter(Filter.sepia)}
                isSelected={filter === Filter.sepia}
                src={imageSepia}
                label='Sepia'
              />
              <FilterImage
                onClick={changeFilter(Filter.vivid)}
                isSelected={filter === Filter.vivid}
                src={imageVivid}
                label='Vivid'
              />
            </OptionsGroup>
          </ControlGroup>
        </TabContainer>
        <TabContainer isActive={selectedTab === Tabs.edit}>
          <EditTabsContainer>
            <EditTabButton
              label='Speed'
              isActive={selectedEdit === EditTabs.speed}
              onClick={() => selectEdit(EditTabs.speed)}
              icon={SpeedIcon}
            />
            <EditTabButton
              label='Trim'
              isActive={selectedEdit === EditTabs.trim}
              onClick={() => selectEdit(EditTabs.trim)}
              icon={TrimIcon}
            />
            <EditTabButton
              label='Zoom'
              isActive={selectedEdit === EditTabs.zoom}
              onClick={() => selectEdit(EditTabs.zoom)}
              icon={ZoomIcon}
            />

            <EditTabButton
              label='Rotate'
              isActive={selectedEdit === EditTabs.rotate}
              onClick={() => selectEdit(EditTabs.rotate)}
              icon={RotateIcon}
            />
            <EditTabButton
              label='Reset'
              isActive={false}
              onClick={() => dispatch(actions.resetTransform())}
              icon={ResetIcon}
              warn
            />
          </EditTabsContainer>
          <HackContainer>
            <ControlGroup label={<ResetLabel onClick={() => dispatch(actions.resetTransform())} />}>
              <OptionsGroup verticalOnDesktop>
                <EditControl label='Speed' isActive={selectedEdit === EditTabs.speed}>
                  <Slider min={0.5} max={2} step={0.01} value={speed} onChange={updateSpeed} />
                </EditControl>
                <EditControl label='Trim' isActive={selectedEdit === EditTabs.trim}>
                  <Range
                    value={[startTime * 100, endTime * 100]}
                    min={0}
                    max={100}
                    step={0.01}
                    pushable={true}
                    onBeforeChange={() => updateTrimState(true)}
                    onChange={updateTrim}
                    onAfterChange={() => updateTrimState(false)}
                  />
                </EditControl>
                <EditControl label='Zoom' isActive={selectedEdit === EditTabs.zoom}>
                  <Slider min={0.5} max={3} step={0.01} value={scale} onChange={updateScale} />
                </EditControl>
                <EditControl label='Rotate' isActive={selectedEdit === EditTabs.rotate}>
                  <Slider min={-180} max={180} value={rotate} onChange={updateRotation} />
                  <RotateButton />
                </EditControl>
              </OptionsGroup>
            </ControlGroup>
          </HackContainer>
        </TabContainer>
        <div className={styles.buttoncontainer}>
          <Button onClick={props.onFinish} label='Add to Cart' black />
          <ShipsIn />
        </div>
      </div>
    </React.Fragment>
  )
}
