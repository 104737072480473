import { WithNull } from '../interfaces'
import { isDate, isString } from './typechecks'
/**
 * Clamps num within the inclusive range specified by the boundary values
 * @param num -
 * @param a - boundary top
 * @param b - boundary bottom
 */
export const clampNumber = (num: number, a: number, b: number): number =>
  Math.max(Math.min(num, Math.max(a, b)), Math.min(a, b))

/**
 * Returns a random integer in the specified range.
 * @param min -
 * @param max -
 */
export const randomIntegerInRange = (min: number, max: number): number =>
  Math.floor(Math.random() * (max - min + 1)) + min

export const prettyBytes = (val: number): string => {
  const nBytes = val
  let out = nBytes + ' bytes'
  const aMultiples = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  for (let nMultiple = 0, nApprox = nBytes / 1000; nApprox > 1; nApprox /= 1000, nMultiple++) {
    out = nApprox.toFixed(1) + ' ' + aMultiples[nMultiple]
  }
  return out
}

/**
 * Returns the time diff in seconds
 * @param start - Start date
 * @param end - End Date
 */
export const timeDiff = (start: WithNull<Date | string>, end: WithNull<Date | string>): number => {
  if (!start || !end) return 0
  const startDate = asDate(start)
  const endDate = asDate(end)
  return (endDate.getTime() - startDate.getTime()) / 1000

  function asDate(val: Date | string): Date {
    if (isString(val)) val = new Date(val)
    if (isDate(val)) return val
    return new Date()
  }
}

/**
 * Computes number rounded to precision
 */
export const round = (val: number, precision = 2): number => {
  const EPSILON = Number.EPSILON || Math.pow(2, -52)
  const modifier = 10 ** precision
  return Math.round((val + EPSILON) * modifier) / modifier
}
