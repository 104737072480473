import { Action, Dispatch, MiddlewareAPI } from 'redux'
import { AppState, Filter, IoMaterial, IoOrientation, IoSize, Loop } from '../constants'
import { FileMeta, User } from './application'
import { Creation } from './creation'
import { Vector2D } from './dimensions'
import { Variant } from './shopify'

// ─────────────────────────────────  Utils  ───────────────────────────────────

/**
 * Creates a type that is aware of the State and the Action types
 */
export interface Middleware<StateType> {
  (api: MiddlewareAPI<Dispatch, StateType>): (
    next: Dispatch<TypedAction>,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ) => (action: TypedAction) => any
}

// ────────────────────────────────  Actions  ──────────────────────────────────

/** Action types */
export enum ActionType {
  // AUTH
  setUser = 'SET_USER',

  // Global
  loadCreation = 'HYDRO_HOMIE',
  setReadyState = 'SET_READY_STATE',

  // DEVICE
  setOrientation = 'SET_ORIENTATION',
  setSize = 'SET_SIZE',
  setMaterial = 'SET_MATERIAL',

  // VIDEO
  selectVideo = 'SET_SELECTED_VIDEO',
  resetVideo = 'RESET_VIDEO',
  setOriginalResource = 'SET_ORIGINAL_RESOURCE',
  // Transforms
  setFilter = 'SET_FILTER',
  setRotation = 'SET_ROTATION',
  setSpeed = 'SET_SPEED',
  setTranslation = 'SET_TRANSLATION',
  setScale = 'SET_SCALE',
  setTrim = 'SET_TRIM',
  setLoop = 'SET_LOOP',
  resetTransforms = 'RESET_TRANSFORMS',

  // Shopify
  setVariants = 'SET_VARIANTS',
  setMinPrice = 'SET_MIN_PRICE',
  setShipsIn = 'SET_SHIPS_IN',

  // Local State
  isTrimming = 'IS_TRIMMING',
  isLoading = 'IS_LOADING',
  setCurrentTime = 'SET_CURRENT_TIME',
  setMobile = 'SET_MOBILE',
}

/**
 * Base Action, will be used as a base to the Typed Action
 */

type BaseAction = Action<ActionType>

/**
 * Application Actions
 * Is a complex type that strictly checks the payload
 */
export type TypedAction =
  // Global
  | (BaseAction & { type: ActionType.loadCreation; payload: Creation })
  | (BaseAction & { type: ActionType.setReadyState; payload: AppState })

  // Creation
  | (BaseAction & { type: ActionType.setUser; payload: User })
  // Device
  | (BaseAction & { type: ActionType.setOrientation; payload: IoOrientation })
  | (BaseAction & { type: ActionType.setSize; payload: IoSize })
  | (BaseAction & { type: ActionType.setMaterial; payload: IoMaterial })
  // Video
  | (BaseAction & {
      type: ActionType.selectVideo
      payload: { file: File; metadata: FileMeta }
    })
  | (BaseAction & { type: ActionType.resetVideo })
  | (BaseAction & { type: ActionType.setOriginalResource; payload: string })
  // Transforms
  | (BaseAction & { type: ActionType.setFilter; payload: Filter })
  | (BaseAction & {
      type: ActionType.setRotation
      payload: { angle: number; add?: boolean; clamp?: boolean }
    })
  | (BaseAction & { type: ActionType.setSpeed; payload: number })
  | (BaseAction & { type: ActionType.setTranslation; payload: Vector2D })
  | (BaseAction & { type: ActionType.setScale; payload: number })
  | (BaseAction & { type: ActionType.setTrim; payload: [number, number] })
  | (BaseAction & { type: ActionType.setLoop; payload: Loop })
  | (BaseAction & { type: ActionType.resetTransforms })

  // Shopify
  | (BaseAction & { type: ActionType.setVariants; payload: Variant[] })
  | (BaseAction & { type: ActionType.setMinPrice; payload: number })
  | (BaseAction & { type: ActionType.setShipsIn; payload: string })

  // Local
  | (BaseAction & { type: ActionType.isTrimming; payload: boolean })
  | (BaseAction & { type: ActionType.isLoading; payload: boolean })
  | (BaseAction & { type: ActionType.setCurrentTime; payload: number })
  | (BaseAction & { type: ActionType.setMobile; payload: boolean })
