import React, { useContext, useEffect, useState } from 'react'
import { PositionController } from '../../components/position-controller'
import { VideoElement } from '../../components/video-component'
import { AppState, CSSFilter, Filter, IoOrientation, MAX_TEXTURE_SIZE } from '../../constants'
import { PreviewContext } from '../../contexts/preview'
import { ScreenPosition, Size } from '../../interfaces'
import { useTypedSelector } from '../../store'
import { asClassName, positionAsCSS, useDebug } from '../../utils'
import styles from './video-container.module.scss'

interface VideoContainerProps {
  containerSize: Size
}

export const VideoContainer: React.FC<VideoContainerProps> = (props) => {
  const debug = useDebug('container:video')
  const { containerSize } = props
  const { frame, video } = useContext(PreviewContext)
  const [mounted, setMounted] = useState(false)
  const readyState = useTypedSelector((state) => state.readyState)
  const hasVideo = useTypedSelector(
    (state) =>
      !!(state.local.videoUrl || state.creation.video.optimizedUrl || state.creation.video.originalUrl),
  )
  debug('hasVideo: %s', hasVideo)
  /* Get values from video state */
  const orientation = useTypedSelector((state) => state.creation.device.orientation)
  const filter = useTypedSelector((state) => state.creation.transform.filter)

  /* Get editable state */
  const isEditable = readyState === AppState.editingCreation

  const showMessage = !mounted && isEditable

  const position = getPosition(containerSize, frame, video, orientation, debug)

  useEffect(() => {
    if (showMessage) {
      setTimeout(() => setMounted(true), 3000)
      return () => setMounted(true)
    }
    return () => {
      // void
    }
  }, [showMessage])

  return (
    <div
      className={styles.container}
      style={{
        ...positionAsCSS(position),
        ...getCSSFilter(filter),
      }}
    >
      {hasVideo ? <VideoElement containerSize={containerSize} /> : null}
      {isEditable ? <PositionController containerPos={position} /> : null}

      <div
        className={asClassName(styles.message, !showMessage ? styles.hide : null)}
        onClick={() => setMounted(true)}
      />
    </div>
  )
}

function getPosition(
  containerSize: Size,
  frame: ScreenPosition,
  video: ScreenPosition,
  orientation: IoOrientation,
  debug: debug.Debugger,
) {
  debug('videoCord: %o', video)

  const frameVertical = {
    width: containerSize.height * (frame.width / frame.height),
    height: containerSize.height,
  }
  const frameHorizontal = {
    width: containerSize.width,
    height: containerSize.width * (frame.height / frame.width),
  }

  const frameSize =
    orientation === IoOrientation.portrait
      ? frameVertical.width > containerSize.width
        ? frameHorizontal
        : frameVertical
      : frameHorizontal.height > containerSize.height
        ? frameVertical
        : frameHorizontal

  let textureSize = frameSize.width / frame.width
  if (textureSize > MAX_TEXTURE_SIZE) {
    textureSize = MAX_TEXTURE_SIZE
  }
  const width = textureSize * video.width
  const height = textureSize * video.height

  const position = {
    width,
    height,
    left: containerSize.width * 0.5 - width * 0.5,
    top: containerSize.height * 0.5 - height * 0.5,
  }
  debug('position: %o', position)
  return position
}

/**
 * Returns a CSS filter
 * @param filter - A filter Enumeration
 */
function getCSSFilter(filter: Filter): { filter: CSSFilter } {
  switch (filter) {
    case Filter.vivid:
      return { filter: CSSFilter.vivid }

    case Filter.sepia:
      return { filter: CSSFilter.sepia }

    case Filter.blackWhite:
      return { filter: CSSFilter.blackWhite }

    default:
      return { filter: CSSFilter.none }
  }
}
