import Hammer from 'hammerjs'
import { RefObject, useEffect, useRef } from 'react'
import { AppState } from '../constants'
import { useTypedSelector } from '../store'

interface HammerEventHandlers {
  onPinch?: HammerListener
  onRotate?: HammerListener
  onPan: HammerListener
}

export function useHammer(handlers: HammerEventHandlers): RefObject<HTMLDivElement> {
  const elemReference = useRef<HTMLDivElement>(null)
  const isEditable = useTypedSelector((state) => state.readyState === AppState.editingCreation)

  useEffect(() => {
    if (elemReference.current && isEditable) {
      const manager = new Hammer.Manager(elemReference.current)
      // const pinch = new Hammer.Pinch()
      // const rotate = new Hammer.Rotate()
      const pan = new Hammer.Pan()
      // pinch.recognizeWith(rotate)
      // pinch.recognizeWith(pan)
      manager.add([pan])
      // manager.on('pinch', ev => handlers.onPinch(ev))
      // manager.on('rotate', ev => handlers.onRotate(ev))
      manager.on('pan', (ev) => handlers.onPan(ev))
      return () => {
        manager.destroy()
      }
    }
    return () => {
      // void
    }
  }, [handlers, isEditable])

  return elemReference
}
