import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import { Application } from './application'
import { ENVIRONMENT, FIREBASE_COLLECTION, IS_EMBEDDED, VERSION } from './constants'
import { initializeStore } from './store'
import { Debug, getCreationId, isEmptyObject } from './utils'

import './styles/global.scss'

if (!IS_EMBEDDED && !getCreationId() && ENVIRONMENT === 'production') {
  window.location.assign('https://infiniteobjects.com/products/video-print/?ref=app-io')
}

const debug = Debug('app')
// eslint-disable-next-line prefer-const
let state = {}
// state = require('./test-states/edit.json')
// state = require('./test-states/edit.json')
const store = initializeStore(state)

// eslint-disable-next-line no-console
console.log(`Infinite Objects Creation tool v${VERSION}`)
debug('Environment: %s', ENVIRONMENT)
debug('Collection: %s', FIREBASE_COLLECTION)

if (!isEmptyObject(state)) debug('Initial State: %o', state)

ReactDOM.render(
  <Provider store={store}>
    <Application />
  </Provider>,
  document.getElementById('root'),
)
